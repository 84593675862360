import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/auth_provider";

const SpinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    height: "95vh",
    flexDirection: "column",
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();

    return (
        <AuthContext.Consumer>
            {({ isLoading, user }) =>
                isLoading ? (
                    <div style={SpinnerStyle}>
                        {" "}
                        <img
                            src={process.env.PUBLIC_URL + "/logo-black-white-kerst.png"}
                            alt="none"
                            width="100"
                            style={{ display: "block" }}
                        />
                    </div>
                ) : (
                    <Route
                        render={(props) =>
                            user ? (
                                <Component {...props} />
                            ) : (
                                <Redirect to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }} />
                            )
                        }
                        {...rest}
                    />
                )
            }
        </AuthContext.Consumer>
    );
};

export default ProtectedRoute;
