import React from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import SelectFormGroup from "../../components/FormGroups/SelectFormGroup";
import BooleanFormGroup from "../../components/FormGroups/BooleanFormGroup";
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton"
import Layout, {
    DroneSettingsBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { numberFromString, commaSeparatedStringOrArraySchema } from "../../utils/zodUtils.js";


const droneConfigSchema = z.object({
    organizationId: z.string().min(1, { message: "Organization is required" }),
    locationId: z.string()
        .min(1, { message: "Select a location" })
        .refine((value) => value !== 'none', { message: "Select a location" }),
    payloadId: z.string()
        .min(1, { message: "Select a payload" })
        .refine((value) => value !== 'none', { message: "Select a payload" }),
    dockId: z.string(),
    augmentation: z.string(),
    navcontrol: z.object({
        control_settings: z.object({
            P_forward: numberFromString.positive(),
            P_sideways: numberFromString.positive(),
            D_forward: numberFromString.positive(),
            D_sideways: numberFromString.positive(),
            Pxy: numberFromString.positive(),
            Dxy: numberFromString.positive(),
            Ixy: numberFromString.positive(),
            Pz: numberFromString.positive(),
            vxy_max: numberFromString.positive(),
            vz_max: numberFromString.positive(),
            a_forward_max: numberFromString.positive(),
        }),
        preferences: z.object({
            auto_sleep: z.boolean(),
            auto_sleep_time_sec: numberFromString.positive(),
            auto_shutdown: z.boolean(),
            auto_shutdown_seconds: numberFromString.positive(),
            is_dock_success_based_on_charging: z.boolean(),
            reboot_on_connection_loss: z.boolean(),
            reboot_on_connection_loss_seconds: numberFromString.positive(),
            skip_calibration: z.boolean(),
        }),
        preflight_checks: z.object({
            battery_percent_enabled: z.boolean(),
            min_battery_percentage: numberFromString.int({ message: "Enter an integer" }).min(0).max(100),
            position_stable_enabled: z.boolean(),
            position_recent_enabled: z.boolean(),
            position_accurate_enabled: z.boolean(),
            remote_connected_enabled: z.boolean(),
            home_position_z_enabled: z.boolean(),
            hires_exposure_enabled: z.boolean(),
            current_flightmode_enabled: z.boolean(),
            cpu_temperature_enabled: z.boolean(),
            start_close_to_first_waypoint_enabled: z.boolean(),
        })
    }),
    poscontrol: z.object({
        camera: z.object({
            exposure_gain_offset: numberFromString.int().positive(),
            max_gain: numberFromString.int({ message: "Enter an integer" }).positive().max(500),
            min_gain: numberFromString.int({ message: "Enter an integer" }).positive().min(100),
        }).refine((data) => data.max_gain > data.min_gain, {
            message: "Maximum gain must be higher than minimum gain",
            path: ["max_gain"],
        }),
        exposure: z.object({
            marker_msv: numberFromString.int({ message: "Enter an integer" }).min(0).max(255),
            tracking_msv: numberFromString.int({ message: "Enter an integer" }).min(0).max(255),
        }),
        debug: z.object({
            debug_save_time: numberFromString.nonnegative()
        })
    }),
    payload: z.object({
        camera_settings: z.object({
            focal_length: numberFromString.positive(),
            max_focus_distance: numberFromString.positive(),
            min_focus_distance: numberFromString.positive(),
            sensor_height: numberFromString.positive(),
            sensor_width: numberFromString.positive(),
            z_offset: numberFromString
        }).refine((data) => data.max_focus_distance >= data.min_focus_distance, {
            message: "Max focus distance must be higher than or equal to min focus distance",
            path: ["max_focus_distance"],
        }),
        uploader: z.object({
            is_disabled: z.boolean(),
            upload_strategy: z.enum(["oldest_first", "newest_first"]),
            order_in_flight_folder: z.enum(["random", "oldest_first", "newest_first"]),
            uploads_per_second: numberFromString.nonnegative(),
            upload_priority_ids: commaSeparatedStringOrArraySchema
        })
    }),
    powermodule: z.object({
        capacity_based_battery: z.boolean()
    }),
    routeplanner: z.object({
        buffer_distance: numberFromString,
        max_flight_duration: numberFromString.positive(),
        max_speed: numberFromString.positive(),
        scan_speed: numberFromString.positive(),
        skip_take_off: z.boolean(),
        takeoff_height: numberFromString.positive(),
        takeoff_buffer: numberFromString
    }),
    streamer: z.object({
        enable_hires_stream: z.boolean(),
        enable_payload_stream: z.boolean(),
        enable_qvio_stream: z.boolean(),
    })
});

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function DroneSettingsPage() {
    const { organizationId: originalOrganizationId, locationId: originalLocationId, droneId } = useParams();
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const { control, handleSubmit, reset, watch, setValue, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(droneConfigSchema)
    });
    const [droneConfigPresets, setDroneConfigPresets] = React.useState(undefined);
    const [model, setModel] = React.useState(undefined);
    const [organizations, setOrganizations] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [payloads, setPayloads] = React.useState([]);
    const [docks, setDocks] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const organizationId = watch("organizationId");
    const previousOrganizationId = usePrevious(organizationId);
    const locationId = watch("locationId");
    const previousLocationId = usePrevious(locationId);
    const dockId = watch("dockId");

    React.useEffect(() => {
        setIsLoading(true);
        api.getDroneConfig(droneId, (droneConfig) => {
            droneConfig.augmentation = droneConfig.augmentation ?? 'none';
            reset(droneConfig);
            setModel(droneConfig.model || "E12");
            setIsLoading(false);
        });
    }, [droneId, reset]);

    React.useEffect(() => {
        api.getOrganizations(setOrganizations);
        api.getPayloads(setPayloads);
        api.getDroneConfigPresets(setDroneConfigPresets);
    }, []);

    React.useEffect(() => {
        if (organizationId) {
            api.getLocations(organizationId, setLocations);
        }
    }, [organizationId]);

    React.useEffect(() => {
        if (organizationId && previousOrganizationId && organizationId !== previousOrganizationId) {
            setValue("locationId", "none");
            setValue("dockId", "none");
        }
    }, [organizationId, previousOrganizationId, setValue]);

    React.useEffect(() => {
        if (locationId && previousLocationId && locationId !== previousLocationId) {
            if (dockId !== "mobile") {
                setValue("dockId", "none");
            }
        }
    }, [locationId, previousLocationId, dockId, setValue]);

    React.useEffect(() => {
        api.getDocks(organizationId, locationId, setDocks);
    }, [organizationId, locationId]);

    const onSubmit = async (droneConfigUpdate) => {
        if (dockId !== 'deprecated') {
            droneConfigUpdate.docks = dockId === 'none' ? [] : [{ id: dockId }];
        }
        // These updates are just for backwards compatibility to also manage the settings on the old locations
        // TODO remove after routeplanner is updated to expect these in the new location
        droneConfigUpdate.bufferDistance = droneConfigUpdate.routeplanner.buffer_distance;
        droneConfigUpdate.max_flight_duration = droneConfigUpdate.routeplanner.max_flight_duration;
        droneConfigUpdate.max_speed = droneConfigUpdate.routeplanner.max_speed;
        droneConfigUpdate.scan_speed = droneConfigUpdate.routeplanner.scan_speed;
        droneConfigUpdate.skipTakeOff = droneConfigUpdate.routeplanner.skip_take_off;
        droneConfigUpdate.takeoff_height = droneConfigUpdate.routeplanner.takeoff_height;
        droneConfigUpdate.takeoff_buffer = droneConfigUpdate.routeplanner.takeoff_buffer;

        droneConfigUpdate.focal_length = droneConfigUpdate.payload.camera_settings.focal_length;
        droneConfigUpdate.sensor_height = droneConfigUpdate.payload.camera_settings.sensor_height;
        droneConfigUpdate.sensor_width = droneConfigUpdate.payload.camera_settings.sensor_width;
        droneConfigUpdate.max_focus_distance = droneConfigUpdate.payload.camera_settings.max_focus_distance;
        droneConfigUpdate.min_focus_distance = droneConfigUpdate.payload.camera_settings.min_focus_distance;
        droneConfigUpdate.z_offset = droneConfigUpdate.payload.camera_settings.z_offset;

        // console.log(droneConfigUpdate)
        await api.updateDroneConfig(droneId, droneConfigUpdate);
        history.push(urls.locationDrone(droneConfigUpdate.organizationId, droneConfigUpdate.locationId, droneId));
    }

    return (
        <Layout>
            <DroneSettingsBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.locationDrone(originalOrganizationId, originalLocationId, droneId)} />
                        {droneId} Settings
                    </>
                } />
                <Card.Body className="p-4 py-0">
                    {isLoading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {getUserIsAdmin() &&
                                <>
                                    <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                                        <FaSave className="mb-1 me-1" />
                                        {isSubmitting ? "Saving..." : "Save"}
                                    </Button>
                                    <CancelButton
                                        className="ms-2 mb-3 mt-1"
                                        href={urls.locationDrone(originalOrganizationId, originalLocationId, droneId)}
                                    />
                                    <SelectFormGroup label="Organization"
                                        name="organizationId"
                                        options={organizations}
                                        control={control}
                                        errors={errors} />
                                </>}
                            <SelectFormGroup label="Location"
                                name="locationId"
                                options={locations.filter(location => !location.deleted)}
                                disabledOption={{ id: "none", name: "Select a location..." }}
                                control={control}
                                errors={errors} />
                            {getUserIsAdmin() && <SelectFormGroup label="Payload"
                                name="payloadId"
                                options={payloads}
                                disabledOption={{ id: "none", name: "Select a payload..." }}
                                control={control}
                                errors={errors} />
                            }
                            {getUserIsAdmin() && <SelectFormGroup label="Dock"
                                name="dockId"
                                options={[{ id: "none", name: "No Dock" }, { id: "mobile", name: "Mobile Dock" }, ...docks]}
                                control={control}
                                errors={errors} />
                            }
                            {getUserIsAdmin() && <SelectFormGroup label="Augmentation"
                                name="augmentation"
                                options={[{ id: "none", name: "None" }, { id: "SIMULATION", name: "Simulation" }, { id: "EMULATION", name: "Emulation" }, { id: "STATIC_CAM", name: "Static camera" }]}
                                control={control}
                                errors={errors} />
                            }
                            {getUserIsAdmin() &&
                                <>
                                    <br />
                                    <h4>Navigation Control</h4>
                                    <h6>Control settings</h6>
                                    <TextFormGroup label="P forward"
                                        name="navcontrol.control_settings.P_forward"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.P_forward}
                                        setValue={setValue} />
                                    <TextFormGroup label="P sideways"
                                        name="navcontrol.control_settings.P_sideways"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.P_sideways}
                                        setValue={setValue} />
                                    <TextFormGroup label="D forward"
                                        name="navcontrol.control_settings.D_forward"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.D_forward}
                                        setValue={setValue} />
                                    <TextFormGroup label="D sideways"
                                        name="navcontrol.control_settings.D_sideways"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.D_sideways}
                                        setValue={setValue} />
                                    <TextFormGroup label="Pxy"
                                        name="navcontrol.control_settings.Pxy"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.Pxy}
                                        setValue={setValue} />
                                    <TextFormGroup label="Dxy"
                                        name="navcontrol.control_settings.Dxy"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.Dxy}
                                        setValue={setValue} />
                                    <TextFormGroup label="Ixy"
                                        name="navcontrol.control_settings.Ixy"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.Ixy}
                                        setValue={setValue} />
                                    <TextFormGroup label="Pz"
                                        name="navcontrol.control_settings.Pz"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.Pz}
                                        setValue={setValue} />
                                    <TextFormGroup label="vxy max"
                                        name="navcontrol.control_settings.vxy_max"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.vxy_max}
                                        setValue={setValue}
                                        unitText="m/s" />
                                    <TextFormGroup label="vz max"
                                        name="navcontrol.control_settings.vz_max"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.vz_max}
                                        setValue={setValue}
                                        unitText="m/s" />
                                    <TextFormGroup label="Max forward acceleration"
                                        name="navcontrol.control_settings.a_forward_max"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.control_settings?.a_forward_max}
                                        setValue={setValue}
                                        unitText="m/s²" />

                                    <br />
                                    <h6>Preferences</h6>
                                    <BooleanFormGroup label="Auto sleep"
                                        name="navcontrol.preferences.auto_sleep"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.auto_sleep}
                                        setValue={setValue} />
                                    <TextFormGroup label="Auto sleep time"
                                        name="navcontrol.preferences.auto_sleep_time_sec"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.auto_sleep_time_sec}
                                        setValue={setValue}
                                        unitText="seconds" />
                                    <BooleanFormGroup label="Auto shutdown"
                                        name="navcontrol.preferences.auto_shutdown"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.auto_shutdown}
                                        setValue={setValue} />
                                    <TextFormGroup label="Auto shutdown time"
                                        name="navcontrol.preferences.auto_shutdown_seconds"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.auto_shutdown_seconds}
                                        setValue={setValue}
                                        unitText="seconds" />
                                    <BooleanFormGroup label="Docking charge check"
                                        name="navcontrol.preferences.is_dock_success_based_on_charging"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.is_dock_success_based_on_charging}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Reboot on connection loss"
                                        name="navcontrol.preferences.reboot_on_connection_loss"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.reboot_on_connection_loss}
                                        setValue={setValue} />
                                    <TextFormGroup label="Reboot on connection loss time"
                                        name="navcontrol.preferences.reboot_on_connection_loss_seconds"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.reboot_on_connection_loss_seconds}
                                        setValue={setValue}
                                        unitText="seconds" />
                                    <BooleanFormGroup label="Skip calibration"
                                        name="navcontrol.preferences.skip_calibration"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preferences?.skip_calibration}
                                        setValue={setValue} />

                                    <br />
                                    <h6>Preflight checks</h6>
                                    <BooleanFormGroup label="Battery percentage"
                                        name="navcontrol.preflight_checks.battery_percent_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.battery_percent_enabled}
                                        setValue={setValue} />
                                    <TextFormGroup label="Minimum battery percentage"
                                        name="navcontrol.preflight_checks.min_battery_percentage"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.min_battery_percentage}
                                        setValue={setValue}
                                        unitText="%" />
                                    <BooleanFormGroup label="Stable position"
                                        name="navcontrol.preflight_checks.position_stable_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.position_stable_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Recent position"
                                        name="navcontrol.preflight_checks.position_recent_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.position_recent_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Accurate position"
                                        name="navcontrol.preflight_checks.position_accurate_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.position_accurate_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Remote control connected"
                                        name="navcontrol.preflight_checks.remote_connected_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.remote_connected_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Start only between -0.7m and 0.7m height"
                                        name="navcontrol.preflight_checks.home_position_z_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.home_position_z_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Hires exposure"
                                        name="navcontrol.preflight_checks.hires_exposure_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.hires_exposure_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Flight mode"
                                        name="navcontrol.preflight_checks.current_flightmode_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.current_flightmode_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="CPU temperature"
                                        name="navcontrol.preflight_checks.cpu_temperature_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.cpu_temperature_enabled}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Start close to first waypoint"
                                        name="navcontrol.preflight_checks.start_close_to_first_waypoint_enabled"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.navcontrol?.preflight_checks?.start_close_to_first_waypoint_enabled}
                                        setValue={setValue} />

                                    <br />
                                    <h4>Position Control</h4>
                                    <h6>Camera settings</h6>
                                    <TextFormGroup label="Exposure gain offset"
                                        name="poscontrol.camera.exposure_gain_offset"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.camera?.exposure_gain_offset}
                                        setValue={setValue} />
                                    <TextFormGroup label="Maximum gain"
                                        name="poscontrol.camera.max_gain"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.camera?.max_gain}
                                        setValue={setValue} />
                                    <TextFormGroup label="Minimum gain"
                                        name="poscontrol.camera.min_gain"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.camera?.min_gain}
                                        setValue={setValue} />

                                    <br />
                                    <h6>Exposure settings</h6>
                                    <TextFormGroup label="Marker msv"
                                        name="poscontrol.exposure.marker_msv"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.exposure?.marker_msv}
                                        setValue={setValue} />
                                    <TextFormGroup label="Tracking msv"
                                        name="poscontrol.exposure.tracking_msv"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.exposure?.tracking_msv}
                                        setValue={setValue} />

                                    <br />
                                    <h6>Debug settings</h6>
                                    <TextFormGroup label="Debug save time"
                                        name="poscontrol.debug.debug_save_time"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.poscontrol?.debug?.debug_save_time}
                                        setValue={setValue}
                                        unitText="seconds" />

                                    <br />
                                    <h4>Payload</h4>
                                    <h6>Camera settings</h6>
                                    <TextFormGroup label="Focal length"
                                        name="payload.camera_settings.focal_length"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.focal_length}
                                        setValue={setValue}
                                        unitText="mm" />
                                    <TextFormGroup label="Max focus distance"
                                        name="payload.camera_settings.max_focus_distance"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.max_focus_distance}
                                        setValue={setValue}
                                        unitText="m" />
                                    <TextFormGroup label="Min focus distance"
                                        name="payload.camera_settings.min_focus_distance"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.min_focus_distance}
                                        setValue={setValue}
                                        unitText="m" />
                                    <TextFormGroup label="Sensor height"
                                        name="payload.camera_settings.sensor_height"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.sensor_height}
                                        setValue={setValue}
                                        unitText="mm" />
                                    <TextFormGroup label="Sensor width"
                                        name="payload.camera_settings.sensor_width"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.sensor_width}
                                        setValue={setValue}
                                        unitText="mm" />
                                    <TextFormGroup label="Z offset"
                                        name="payload.camera_settings.z_offset"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.camera_settings?.z_offset}
                                        setValue={setValue}
                                        unitText="m" />
                                </>
                            }
                            <br />
                            <h6>Uploader</h6>
                            <BooleanFormGroup label="Disabled"
                                name="payload.uploader.is_disabled"
                                control={control}
                                errors={errors}
                                defaultValue={droneConfigPresets?.[model]?.default?.payload?.uploader?.is_disabled}
                                setValue={setValue} />
                            {getUserIsAdmin() &&
                                <>
                                    <SelectFormGroup label="Upload strategy"
                                        name="payload.uploader.upload_strategy"
                                        options={[{ id: "oldest_first", name: "Oldest first" }, { id: "newest_first", name: "Newest first" }]}
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.uploader?.upload_strategy}
                                        setValue={setValue} />
                                    <SelectFormGroup label="Upload order within flight folder"
                                        name="payload.uploader.order_in_flight_folder"
                                        options={[{ id: "random", name: "Random" },
                                        { id: "oldest_first", name: "Oldest first" },
                                        { id: "newest_first", name: "Newest first" }]}
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.uploader?.order_in_flight_folder}
                                        setValue={setValue} />
                                    <TextFormGroup label="Uploads per second"
                                        placeholder="0 means no limiting of upload rate"
                                        name="payload.uploader.uploads_per_second"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.uploader?.uploads_per_second}
                                        setValue={setValue} />
                                    <TextFormGroup label="Prioritized flight ids"
                                        placeholder="Comma separated flight ids"
                                        name="payload.uploader.upload_priority_ids"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.payload?.uploader?.upload_priority_ids?.join(',') ?? undefined}
                                        setValue={setValue} />
                                    <br />
                                    <h4>Powermodule</h4>
                                    <BooleanFormGroup label="Battery percentage"
                                        name="powermodule.capacity_based_battery"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.powermodule?.capacity_based_battery}
                                        setValue={setValue}
                                        trueLabel="Based on capacity"
                                        falseLabel="Based on voltage" />
                                    <br />
                                    <h4>Route planner</h4>
                                    <TextFormGroup label="Buffer distance"
                                        name="routeplanner.buffer_distance"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.buffer_distance}
                                        setValue={setValue}
                                        unitText="m" />
                                    <TextFormGroup label="Max flight duration"
                                        name="routeplanner.max_flight_duration"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.max_flight_duration}
                                        setValue={setValue}
                                        unitText="seconds" />
                                    <TextFormGroup label="Max speed"
                                        name="routeplanner.max_speed"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.max_speed}
                                        setValue={setValue}
                                        unitText="m/s" />
                                    <TextFormGroup label="Scan speed"
                                        name="routeplanner.scan_speed"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.scan_speed}
                                        setValue={setValue}
                                        unitText="m/s" />
                                    <BooleanFormGroup label="Camera box - remove xyz commands in takeoff"
                                        name="routeplanner.skip_take_off"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.skip_take_off}
                                        setValue={setValue} />
                                    <TextFormGroup label="Takeoff height"
                                        name="routeplanner.takeoff_height"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.takeoff_height}
                                        setValue={setValue}
                                        unitText="m" />
                                    <TextFormGroup label="Takeoff buffer"
                                        name="routeplanner.takeoff_buffer"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.routeplanner?.takeoff_buffer}
                                        setValue={setValue}
                                        unitText="m" />
                                    <br />
                                    <h4>Streamer</h4>
                                    <BooleanFormGroup label="Enable hires stream"
                                        name="streamer.enable_hires_stream"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.streamer?.enable_hires_stream}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Enable payload stream"
                                        name="streamer.enable_payload_stream"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.streamer?.enable_payload_stream}
                                        setValue={setValue} />
                                    <BooleanFormGroup label="Enable qvio stream"
                                        name="streamer.enable_qvio_stream"
                                        control={control}
                                        errors={errors}
                                        defaultValue={droneConfigPresets?.[model]?.default?.streamer?.enable_qvio_stream}
                                        setValue={setValue} />
                                </>
                            }
                            <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                                <FaSave className="mb-1 me-1" />
                                {isSubmitting ? "Saving..." : "Save"}
                            </Button>
                            <CancelButton
                                className="ms-2 mb-3 mt-1"
                                href={urls.locationDrone(originalOrganizationId, originalLocationId, droneId)}
                            />
                        </Form>)}
                </Card.Body>
            </Card>
        </Layout>
    );
}
